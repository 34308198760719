<template>
    <div class="fill-height">
        <app-bar :title="t('dashboard_title')"></app-bar>

        <v-progress-linear
            v-if="overlay"
            absolute
            indeterminate
        ></v-progress-linear>

        <div class="dashboard">
            <div class="dashboard-units">
                <div class="dashboard-stats">
                    <div class="dashboard-stat">
                        <div class="dashboard-stat-title"><i class="ai ai-registration"></i>{{ t('dashboard_title_registration') }}</div>
                        <table class="dashboard-stat-items">
                            <tr>
                                <td>{{ t('dashboard_stat_day') }}</td>
                                <td><div></div></td>
                                <td>{{ registrations.day }}</td>
                            </tr>
                            <tr>
                                <td>{{ t('dashboard_stat_week') }}</td>
                                <td><div></div></td>
                                <td>{{ registrations.week }}</td>
                            </tr>
                            <tr>
                                <td>{{ t('dashboard_stat_month') }}</td>
                                <td><div></div></td>
                                <td>{{ registrations.month }}</td>
                            </tr>
                            <tr>
                                <td>{{ t('dashboard_stat_year') }}</td>
                                <td><div></div></td>
                                <td>{{ registrations.year }}</td>
                            </tr>
                            <tr>
                                <td>{{ t('dashboard_stat_total') }}</td>
                                <td><div></div></td>
                                <td>{{ statTotal.totalRegistrations }}</td>
                            </tr>
                        </table>
                    </div>
                    <div class="dashboard-stat">
                        <div class="dashboard-stat-title"><i class="ai ai-workout"></i>{{ t('dashboard_title_workout_executions') }}</div>
                        <table class="dashboard-stat-items">
                            <tr>
                                <td>{{ t('dashboard_stat_day') }}</td>
                                <td><div></div></td>
                                <td>{{ workoutExecutions.day }}</td>
                            </tr>
                            <tr>
                                <td>{{ t('dashboard_stat_week') }}</td>
                                <td><div></div></td>
                                <td>{{ workoutExecutions.week }}</td>
                            </tr>
                            <tr>
                                <td>{{ t('dashboard_stat_month') }}</td>
                                <td><div></div></td>
                                <td>{{ workoutExecutions.month }}</td>
                            </tr>
                            <tr>
                                <td>{{ t('dashboard_stat_year') }}</td>
                                <td><div></div></td>
                                <td>{{ workoutExecutions.year }}</td>
                            </tr>
                            <tr>
                                <td>{{ t('dashboard_stat_total') }}</td>
                                <td><div></div></td>
                                <td>{{ statTotal.totalWorkoutExecutions }}</td>
                            </tr>
                        </table>
                    </div>
                    <div class="dashboard-stat mr-0">
                        <div class="dashboard-stat-title"><i class="ai ai-program"></i>{{ t('dashboard_title_program_executions') }}</div>
                        <table class="dashboard-stat-items">
                            <tr>
                                <td>{{ t('dashboard_stat_day') }}</td>
                                <td><div></div></td>
                                <td>{{ programExecutions.day }}</td>
                            </tr>
                            <tr>
                                <td>{{ t('dashboard_stat_week') }}</td>
                                <td><div></div></td>
                                <td>{{ programExecutions.week }}</td>
                            </tr>
                            <tr>
                                <td>{{ t('dashboard_stat_month') }}</td>
                                <td><div></div></td>
                                <td>{{ programExecutions.month }}</td>
                            </tr>
                            <tr>
                                <td>{{ t('dashboard_stat_year') }}</td>
                                <td><div></div></td>
                                <td>{{ programExecutions.year }}</td>
                            </tr>
                            <tr>
                                <td>{{ t('dashboard_stat_total') }}</td>
                                <td><div></div></td>
                                <td>{{ statTotal.totalProgramExecutions }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="dashboard-stat mt-10 ml-9">
                    <div class="dashboard-stat-title"><i class="ai ai-swimmer"></i>{{ t('dashboard_title_top_swimmers') }}</div>
                    <ul class="dashboard-stat-rankings">
                        <li v-for="rank in ranks.data">
                            <a
                                :href="`/trainees/${rank.userId}`"
                                @click.prevent="viewUser(rank.userId)"
                            >
                                <span>#{{ rank.rank }}</span>{{ rank.userFirstName }} {{ rank.userLastName }} - {{ Math.round(rank.distance / 1000) }}km
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="dashboard-charts">
                <div class="dashboard-chart">
                    <div class="dashboard-chart-title" v-html="t('dashboard_title_total_distance')"></div>
                    <div class="dashboard-chart-bar">
                        <div class="dashboard-chart-bar-step blue"></div>
                        <div class="dashboard-chart-bar-value">{{ statTotal._totalDistance }}</div>
                    </div>
                </div>
                <div class="dashboard-chart">
                    <div class="dashboard-chart-title" v-html="t('dashboard_title_total_duration')"></div>
                    <div class="dashboard-chart-bar">
                        <div class="dashboard-chart-bar-step purple"></div>
                        <div class="dashboard-chart-bar-value">{{ statTotal._totalDuration }}</div>
                    </div>
                </div>
                <div class="dashboard-chart">
                    <div class="dashboard-chart-title">{{ t('dashboard_title_genders') }}</div>
                    <div class="dashboard-chart-body" id="gender-chart"></div>
                </div>
                <div class="dashboard-chart">
                    <div class="dashboard-chart-title">{{ t('dashboard_title_ages') }}</div>
                    <div class="dashboard-chart-body" id="age-chart"></div>
                </div>
                <div class="dashboard-chart">
                    <div class="dashboard-chart-title">{{ t('dashboard_title_levels') }}</div>
                    <div class="dashboard-chart-body" id="level-chart"></div>
                </div>
                <div class="dashboard-chart">
                    <div class="dashboard-chart-title">{{ t('dashboard_title_strokes') }}</div>
                    <div class="dashboard-chart-body" id="stroke-chart"></div>
                </div>
                <div class="dashboard-chart">
                    <div class="dashboard-chart-title dashboard-chart-title-v2 pb-10">{{ t('dashboard_title_top_countries') }}</div>
                    <div class="dashboard-chart-body" id="country-chart"></div>
                </div>
                <div class="dashboard-chart">
                    <div class="dashboard-chart-title dashboard-chart-title-v2 pb-10">{{ t('dashboard_title_top_cities') }}</div>
                    <div class="dashboard-chart-body" id="city-chart"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { computed, defineComponent, onMounted, reactive, toRefs } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { useStore } from '@/store'
    import { useRouter } from '@/composition-api'
    import { useEnums } from '@/core/defaults'
    import { IStatPeriod, Stat } from '@/core/models/stat'
    import { StatTotal } from '@/core/models/stat-total'
    import { RankCollection } from '@/store/modules/rank-collection'
    import * as am5 from '@amcharts/amcharts5'
    import * as am5xy from '@amcharts/amcharts5/xy'
    import * as am5percent from '@amcharts/amcharts5/percent'
    import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'

    export default defineComponent({
        /**
         * Component constructor.
         * @param props
         */
        setup(props) {
            const state = reactive({
                overlay: true,
                registrations: <IStatPeriod>{ day: 0, week: 0, month: 0, year: 0 },
                workoutExecutions: <IStatPeriod>{ day: 0, week: 0, month: 0, year: 0 },
                programExecutions: <IStatPeriod>{ day: 0, week: 0, month: 0, year: 0 },
            })

            const store = useStore()
            const router = useRouter()

            const stat = computed(() => <Stat>store.getters['stat/get'])
            const statTotal = computed(() => <StatTotal>store.getters['statTotal/get'])
            const ranks = computed(() => <RankCollection>store.getters['rankCollection/get'])

            const viewUser = (id: number) => router.push({ name: 'trainees.show', params: { id: <any>id } })
            
            onMounted(() => {
                store.dispatch('statTotal/get').then(() => {
                    state.overlay = false
                    captureRanks()
                    captureStats()
                })
            })

            const captureRanks = () => {
                store.dispatch('rankCollection/get', { per_page: 5 })
            }

            const captureStats = () => {
                store.dispatch('stat/get').then(() => {
                    state.registrations = stat.value.registrations
                    state.workoutExecutions = stat.value.workoutExecutions
                    state.programExecutions = stat.value.programExecutions

                    const GENDER_root = am5.Root.new('gender-chart')
                    GENDER_root.setThemes([ am5themes_Animated.new(GENDER_root) ])

                    const GENDER_chart = GENDER_root.container.children.push(am5percent.PieChart.new(GENDER_root, {
                        layout: GENDER_root.verticalLayout,
                        innerRadius: am5.percent(50),
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingTop: 20,
                        paddingBottom: 20
                    }))

                    const GENDER_series = GENDER_chart.series.push(am5percent.PieSeries.new(GENDER_root, {
                        valueField: 'value',
                        categoryField: 'key',
                        alignLabels: false
                    }))

                    GENDER_series.labels.template.setAll({
                        textType: 'circular',
                        centerX: 0,
                        centerY: 0
                    })

                    GENDER_series.data.setAll(stat.value.userGenders)

                    const GENDER_legend = GENDER_chart.children.push(am5.Legend.new(GENDER_root, {
                        centerX: am5.percent(50),
                        x: am5.percent(50),
                        marginTop: 15,
                        marginBottom: 15,
                    }))

                    GENDER_legend.data.setAll(GENDER_series.dataItems)
                    GENDER_series.appear(1000, 100)


                    const AGE_root = am5.Root.new('age-chart')
                    AGE_root.setThemes([ am5themes_Animated.new(AGE_root) ])

                    const AGE_chart = AGE_root.container.children.push(am5percent.PieChart.new(AGE_root, {
                        layout: AGE_root.verticalLayout,
                        paddingLeft: 65,
                        paddingRight: 65,
                        paddingTop: 65,
                        paddingBottom: 65
                    }))

                    const AGE_series = AGE_chart.series.push(am5percent.PieSeries.new(AGE_root, {
                        valueField: 'value',
                        categoryField: 'key'
                    }))

                    AGE_series.data.setAll(stat.value.userAges)
                    AGE_series.appear(1000, 100)


                    const LEVEL_root = am5.Root.new('level-chart')
                    LEVEL_root.setThemes([ am5themes_Animated.new(LEVEL_root) ])

                    const LEVEL_chart = LEVEL_root.container.children.push(am5percent.PieChart.new(LEVEL_root, {
                        layout: LEVEL_root.verticalLayout,
                        paddingLeft: 70,
                        paddingRight: 70,
                        paddingTop: 70,
                        paddingBottom: 70
                    }))

                    const LEVEL_series = LEVEL_chart.series.push(am5percent.PieSeries.new(LEVEL_root, {
                        valueField: 'value',
                        categoryField: 'key'
                    }))

                    LEVEL_series.data.setAll(stat.value.userLevels)

                    const LEVEL_legend = LEVEL_chart.children.push(am5.Legend.new(LEVEL_root, {
                        centerX: am5.percent(50),
                        x: am5.percent(50),
                        marginTop: 15,
                        marginBottom: 15
                    }))

                    LEVEL_legend.data.setAll(LEVEL_series.dataItems)
                    LEVEL_series.appear(1000, 100)


                    const STROKE_root = am5.Root.new('stroke-chart')
                    STROKE_root.setThemes([ am5themes_Animated.new(STROKE_root) ])

                    const STROKE_chart = STROKE_root.container.children.push(am5percent.PieChart.new(STROKE_root, {
                        layout: STROKE_root.verticalLayout,
                        innerRadius: am5.percent(50),
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingTop: 20,
                        paddingBottom: 20
                    }))

                    const STROKE_series = STROKE_chart.series.push(am5percent.PieSeries.new(STROKE_root, {
                        valueField: 'value',
                        categoryField: 'key',
                        alignLabels: false
                    }))

                    STROKE_series.labels.template.setAll({
                        textType: 'circular',
                        centerX: 0,
                        centerY: 0
                    })

                    STROKE_series.data.setAll(stat.value.userStrokes)

                    const STROKE_legend = STROKE_chart.children.push(am5.Legend.new(STROKE_root, {
                        centerX: am5.percent(50),
                        x: am5.percent(50),
                        marginTop: 15,
                        marginBottom: 15,
                    }))

                    STROKE_legend.data.setAll(STROKE_series.dataItems)
                    STROKE_series.appear(1000, 100)

                    const COUNTRY_root = am5.Root.new('country-chart')

                    COUNTRY_root.setThemes([ am5themes_Animated.new(COUNTRY_root) ])


                    const COUNTRY_chart = COUNTRY_root.container.children.push(am5xy.XYChart.new(COUNTRY_root, {
                        panX: true,
                        panY: true,
                        wheelX: 'panX',
                        wheelY: 'zoomX',
                        pinchZoomX: true
                    }))

                    const COUNTRY_cursor = COUNTRY_chart.set('cursor', am5xy.XYCursor.new(COUNTRY_root, {}))
                    COUNTRY_cursor.lineY.set('visible', false)


                    const COUNTRY_xRenderer = am5xy.AxisRendererX.new(COUNTRY_root, { minGridDistance: 30 })
                    COUNTRY_xRenderer.labels.template.setAll({
                        rotation: -90,
                        centerY: am5.p50,
                        centerX: am5.p100,
                        paddingRight: 15
                    })

                    COUNTRY_xRenderer.grid.template.setAll({ location: 1 })

                    const COUNTRY_xAxis = COUNTRY_chart.xAxes.push(am5xy.CategoryAxis.new(COUNTRY_root, {
                        maxDeviation: 0.3,
                        categoryField: 'key',
                        renderer: COUNTRY_xRenderer,
                        tooltip: am5.Tooltip.new(COUNTRY_root, {})
                    }))

                    const COUNTRY_yAxis = COUNTRY_chart.yAxes.push(am5xy.ValueAxis.new(COUNTRY_root, {
                        maxDeviation: 0.3,
                        renderer: am5xy.AxisRendererY.new(COUNTRY_root, {
                            strokeOpacity: 0.1
                        })
                    }))

                    const COUNTRY_series = COUNTRY_chart.series.push(am5xy.ColumnSeries.new(COUNTRY_root, {
                        name: 'Series 1',
                        xAxis: COUNTRY_xAxis,
                        yAxis: COUNTRY_yAxis,
                        valueYField: 'value',
                        sequencedInterpolation: true,
                        categoryXField: 'key',
                        tooltip: am5.Tooltip.new(COUNTRY_root, {
                            labelText: '{valueY}'
                        })
                    }))

                    COUNTRY_series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5, strokeOpacity: 0 })
                    COUNTRY_series.columns.template.adapters.add('fill', function(fill, target) {
                        return COUNTRY_chart.get('colors')?.getIndex(COUNTRY_series.columns.indexOf(target))
                    })

                    COUNTRY_series.columns.template.adapters.add('stroke', function(stroke, target) {
                        return COUNTRY_chart.get('colors')?.getIndex(COUNTRY_series.columns.indexOf(target))
                    })

                    COUNTRY_xAxis.data.setAll(stat.value.userCountries)
                    COUNTRY_series.data.setAll(stat.value.userCountries)

                    COUNTRY_series.appear(1000)
                    COUNTRY_chart.appear(1000, 100)



                    const CITY_root = am5.Root.new('city-chart')

                    CITY_root.setThemes([ am5themes_Animated.new(CITY_root) ])


                    const CITY_chart = CITY_root.container.children.push(am5xy.XYChart.new(CITY_root, {
                        panX: true,
                        panY: true,
                        wheelX: 'panX',
                        wheelY: 'zoomX',
                        pinchZoomX: true
                    }))

                    const CITY_cursor = CITY_chart.set('cursor', am5xy.XYCursor.new(CITY_root, {}))
                    CITY_cursor.lineY.set('visible', false)


                    const CITY_xRenderer = am5xy.AxisRendererX.new(CITY_root, { minGridDistance: 30 })
                    CITY_xRenderer.labels.template.setAll({
                        rotation: -90,
                        centerY: am5.p50,
                        centerX: am5.p100,
                        paddingRight: 15
                    })

                    CITY_xRenderer.grid.template.setAll({
                        location: 1
                    })

                    const CITY_xAxis = CITY_chart.xAxes.push(am5xy.CategoryAxis.new(CITY_root, {
                        maxDeviation: 0.3,
                        categoryField: 'key',
                        renderer: CITY_xRenderer,
                        tooltip: am5.Tooltip.new(CITY_root, {})
                    }))

                    const CITY_yAxis = CITY_chart.yAxes.push(am5xy.ValueAxis.new(CITY_root, {
                        maxDeviation: 0.3,
                        renderer: am5xy.AxisRendererY.new(CITY_root, {
                            strokeOpacity: 0.1
                        })
                    }))

                    const CITY_series = CITY_chart.series.push(am5xy.ColumnSeries.new(CITY_root, {
                        name: 'Series 1',
                        xAxis: CITY_xAxis,
                        yAxis: CITY_yAxis,
                        valueYField: 'value',
                        sequencedInterpolation: true,
                        categoryXField: 'key',
                        tooltip: am5.Tooltip.new(CITY_root, {
                            labelText: '{valueY}'
                        })
                    }))

                    CITY_series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5, strokeOpacity: 0 })
                    CITY_series.columns.template.adapters.add('fill', function(fill, target) {
                        return CITY_chart.get('colors')?.getIndex(CITY_series.columns.indexOf(target))
                    })

                    CITY_series.columns.template.adapters.add('stroke', function(stroke, target) {
                        return CITY_chart.get('colors')?.getIndex(CITY_series.columns.indexOf(target))
                    })

                    CITY_xAxis.data.setAll(stat.value.userCities)
                    CITY_series.data.setAll(stat.value.userCities)

                    CITY_series.appear(1000)
                    CITY_chart.appear(1000, 100)
                })
            }

            return {
                statTotal: statTotal.value,
                stat: stat.value,
                ranks: ranks.value,
                viewUser,
                ...useI18n(),
                ...useEnums(),
                ...toRefs(state)
            }
        }
    })
</script>

<style scoped>
    #chart {
        width: 100%;
        height: 500px;
    }
</style>
