<template>
    <v-dialog
        v-model="touched"
        max-width="480"
        persistent
        scrollable
    >
        <template #activator="{ on, attrs }">
            <slot v-bind="{ on, attrs }"></slot>
        </template>

        <v-card>
            <v-card-title class="customized-primary white--text">{{ t('user_card_title_profile') }}</v-card-title>

            <v-card-text class="pb-0">
                <v-form class="mt-6">
                    <v-select
                        v-model="value.level"
                        :items="levels()"
                        type="text"
                        :label="t('user_label_level')"
                        required
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        :error-messages="form.errors.get('level')"
                    ></v-select>
                    <div class="form-group form-group-extra">
                        <template v-for="(stroke, index) in value.strokes">
                            <span class="form-group-title">{{ stroke.name }}</span>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        v-model="value.strokes[index].time50m"
                                        :key="'stroke_50_' + stroke.id"
                                        type="text"
                                        :label="t('user_label_stroke_time_50m')"
                                        required
                                        outlined
                                        dense
                                        v-maska="'##:##:##.###'"
                                        disabled
                                        :error-messages="form.errors.get(`strokes.${index}.time_50m`)"
                                    ></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                        v-model="value.strokes[index].time100m"
                                        :key="'stroke_100_' + stroke.id"
                                        type="text"
                                        :label="t('user_label_stroke_time_100m')"
                                        required
                                        outlined
                                        dense
                                        v-maska="'##:##:##.###'"
                                        disabled
                                        :error-messages="form.errors.get(`strokes.${index}.time_100m`)"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </template>
                    </div>
                    <div class="form-group">
                        <span class="form-group-title">{{ t('user_label_locations') }}</span>
                        <v-checkbox
                            v-for="(location, index) in locations()"
                            v-model="value.swimmingLocations"
                            :key="'location_' + location.value"
                            :value="location.value"
                            :label="location.text"
                            :hide-details="index !== locations().length - 1"
                            multiple
                            dense
                            :error-messages="form.errors.get('swimming_locations')"
                        />
                    </div>
                    <div class="form-group">
                        <span class="form-group-title">{{ t('user_label_goals') }}</span>
                        <v-checkbox
                            v-for="(goal, index) in value.goals"
                            v-model="value.goals[index].enabled"
                            :key="'goal_' + goal.id"
                            :label="goal.name"
                            :hide-details="index !== value.goals.length - 1"
                            dense
                            :error-messages="form.errors.get('user_goals')"
                        />
                    </div>
                    <v-select
                        v-model="value.distanceUnit"
                        :items="distanceUnits()"
                        type="text"
                        :label="t('user_label_distance_unit')"
                        required
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        :error-messages="form.errors.get('distance_unit')"
                    ></v-select>
                    <v-text-field
                        v-model="value.swimmingGoalMeters"
                        type="number"
                        :label="t('user_label_yearly_goal')"
                        required
                        outlined
                        dense
                        :error-messages="form.errors.get('swimming_goal_meters')"
                    ></v-text-field>
                    <v-text-field
                        v-model="value.trainingCountWeek"
                        type="number"
                        :label="t('user_label_weekly_sessions')"
                        required
                        outlined
                        dense
                        :error-messages="form.errors.get('training_count_week')"
                    ></v-text-field>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-btn
                    color="primary"
                    @click="submit"
                    width="45%"
                    :loading="form.busy"
                    :disabled="form.busy"
                >{{ t('general_action_update') }}</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="orange"
                    @click="cancel"
                    width="45%"
                    dark
                >{{ t('general_action_cancel') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
    import { defineComponent, reactive, toRefs } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { useStore } from '@/store'
    import { useForm } from '@/core/form'
    import { useEnums } from '@/core/defaults'
    import { User } from '@/core/models/user'

    const fillable = [
        'level',
        'distance_unit',
        'swimming_locations',
        'swimming_goal_meters',
        'user_goals',
        'user_strokes',
        'training_count_week',
    ]

    export default defineComponent({
        name: 'UserProfileModal',

        props: {
            value: {
                type: User,
                required: true
            }
        },

        /**
         * Component constructor.
         *
         * @param props
         * @param context
         */
        setup(props, context) {
            const store = useStore()

            const i18n = useI18n()

            const state = reactive({
                touched: false,
            })

            const form = reactive(useForm())

            const submit = () => {
                form
                    .usingSubmit(
                        store.dispatch('user/update',
                            { id: props.value.id, payload: props.value.payload(fillable) }
                        )
                    )
                    .then(() => {
                        state.touched = false
                        store.dispatch('notify', { color: 'success', message: i18n.t('general_notice_data_updated') })
                    })
            }

            const cancel = () => {
                form.errors.clear()
                state.touched = false
                context.emit('cancel')
            }

            return {
                form,
                submit,
                cancel,
                ...useEnums(),
                ...useI18n(),
                ...toRefs(state)
            }
        }
    })
</script>
