<template>
    <div>
        <app-bar
            :title="event.name"
        />

        <v-layout>
            <v-progress-linear
                v-if="overlay"
                absolute
                indeterminate
            ></v-progress-linear>

            <div class="pt-8 pb-12 px-8">
                <div v-if="permitted('admin_event_management')" class="pb-10">
                    <v-btn
                        @click="edit"
                        class="sm mr-5"
                        color="primary"
                    >{{ t('general_action_edit') }}</v-btn>
                    <v-btn
                        class="sm mr-5"
                        color="primary"
                        outlined
                        @click.prevent="suspend(event)"
                    >
                        {{ event.enabled ? t('general_action_suspend') : t('general_action_unsuspend') }}
                    </v-btn>
                    <v-btn
                        class="sm"
                        color="orange"
                        outlined
                        @click.prevent="del(event)"
                    >{{ t('general_action_delete') }}</v-btn>
                </div>
                <div class="float-left mr-10" style="max-width: 394px">
                    <img
                        class=""
                        v-if="event.photoUrl"
                        :src="event.photoUrl"
                        width="394"
                    />
                    <div class="form-subtitle">{{ t('event_label_description') }}:</div>
                    <div class="form-subtitle-description" v-html="event.description"></div>
                    <card-unit :items="items()">
                        <template #title>{{ t('event_label_id') }}: {{ event.id }}</template>
                    </card-unit>
                    <template v-if="event.photos && event.photos.length">
                        <div class="form-subtitle mb-3">{{ t('event_label_photos') }}:</div>
                        <v-row>
                            <v-col
                                v-for="photo in event.photos"
                                :key="photo"
                                cols="6"
                            >
                                <v-sheet
                                    color="white"
                                    elevation="0"
                                    height="185"
                                    width="185"
                                    rounded="lg"
                                    class="pt-6"
                                >
                                    <v-img
                                        :lazy-src="photo"
                                        :src="photo"
                                        max-height="135"
                                        max-width="135"
                                        class="mx-auto"
                                    ></v-img>
                                </v-sheet>
                            </v-col>
                        </v-row>
                    </template>
                </div>
                <div class="float-left mr-10">
                    <v-tabs
                        v-model="currentTab"
                        height="52"
                    >
                        <div class="tabs-line"></div>
                        <v-tabs-slider></v-tabs-slider>

                        <template>
                            <v-tab class="pl-0">{{ t('event_label_attendees') }}</v-tab>
                            <v-tab-item class="pt-7">
                                <card-unit
                                    untitled
                                    :items="participants.data.map(u => { return { text: u.fullName, route: { name: 'trainees.show', params: { id: u.id } } } })">
                                    <template #actions>
                                        <v-card-actions
                                            class="px-0 mx-n3 mt-4"
                                            v-if="participantMeta && participantMeta.total > 0"
                                        >
                                            <v-pagination
                                                v-model="participantQueries.page"
                                                :length="participantPageCount"
                                                total-visible="7"
                                            ></v-pagination>
                                            <span class="pagination-meta">{{ t('general_pagination_meta', { from: participantMeta.from, to: participantMeta.to, total: participantMeta.total }) }}</span>
                                        </v-card-actions>
                                    </template>
                                </card-unit>
                                <v-btn
                                    v-if="participants.data.length"
                                    @click="_exportUsers"
                                    class="exs mt-2"
                                    color="primary"
                                    small
                                >{{ t('general_action_export') }}</v-btn>
                            </v-tab-item>

                            <v-tab>{{ t('event_label_ranking') }}</v-tab>
                            <v-tab-item class="pt-7">
                                <v-select
                                    v-model="scheduleId"
                                    :items="schedules"
                                    required
                                    outlined
                                    append-icon="mdi-chevron-down"
                                    dense
                                    style="width: 394px"
                                ></v-select>
                                <card-unit
                                    untitled
                                    :items="ranks.data.map(r => { return { text: r.userFirstName + ' ' + r.userLastName + ' | ' + r.distance + ' m', value: '#' + r.rank } })">
                                </card-unit>
                                <v-btn
                                    v-if="ranks.data.length"
                                    @click="_exportRanks"
                                    class="exs mt-7"
                                    color="primary"
                                    small
                                >{{ t('general_action_export') }}</v-btn>
                            </v-tab-item>

                            <v-tab>{{ t('event_label_schedules') }}</v-tab>
                            <v-tab-item class="pt-7">
                                <card-unit
                                    untitled
                                    :items="event.schedules.map((s, i) => { return { text: '#' + (i + 1), value: s.startAt.format('DD/MM/YYYY HH:mm') + ' - ' + s.endAt.format('DD/MM/YYYY HH:mm') } })">
                                </card-unit>
                            </v-tab-item>
                        </template>
                    </v-tabs>
                </div>
            </div>

        </v-layout>
    </div>
</template>

<script lang="ts">
    import { computed, defineComponent, onMounted, reactive, toRefs, watch } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { useStore } from '@/store'
    import { useRouter } from '@/composition-api'
    import { useEnums } from '@/core/defaults'
    import { useUserHttp } from '@/core/api/users'
    import { useRankHttp } from '@/core/api/ranks'
    import { Event } from '@/core/models/event'
    import { UserCollection } from '@/store/modules/user-collection'
    import { RankCollection } from '@/store/modules/rank-collection'
    import moment from 'moment/moment'
    import * as time from '@/core/utils/time-helper'
    import { pageCount } from '@/core/utils/pagination'
    import CardUnit from '@/components/CardUnit.vue'

    export default defineComponent({
        components: {
            CardUnit
        },

        /**
         * Component constructor.
         *
         * @param props
         */
        setup(props) {
            const store = useStore()
            const router = useRouter()
            const enums = useEnums()
            const i18n = useI18n()
            const id = router.currentRoute.params['id']

            const participantQueries = reactive({
                user_event_id: id,
                page: 1,
                per_page: 20,
                sort: '-id',
                only_active: 1,
            })

            const state = reactive({
                overlay: true,
                currentTab: <number | null>null,
                photo: <File | undefined>undefined,
                participantMeta: undefined,
                participantPageCount: 1,
                scheduleId: <number | undefined>0,
            })

            const participants = computed(() => <UserCollection>store.getters['userCollection/get'])
            const ranks = computed(() => <RankCollection>store.getters['rankCollection/get'])
            const schedules = computed(() => [{ text: '-', value: 0 }].concat(eventModel.value.schedules.map(item => {
                return { text: item.startAt.format('DD/MM/YYYY HH:mm') + ' - ' + item.endAt.format('DD/MM/YYYY HH:mm'), value: item.id }
            })))

            const capture = () => {
                store.dispatch('event/get', { id: id, payload: { relations: 'user,stroke,schedules', append: 'status' } }).then(() => {
                    store.dispatch('userCollection/get', participantQueries).then((data) => {
                        state.participantMeta = data.meta
                        state.participantPageCount = pageCount(data.meta)
                        state.overlay = false
                    })
                })
            }

            const captureRanks = () => {
                state.overlay = true
                store.dispatch('rankCollection/get', { event_id: id, schedule_id: state.scheduleId }).then(() => {
                    state.overlay = false
                })
            }

            const eventModel = computed(() => <Event>store.getters['event/get'])

            const suspend = (eventModel: Event) => {
                state.overlay = true
                store.dispatch('event/update', { id: eventModel.id, payload: { enabled: ! eventModel.enabled, only: 'enabled' } }).then(() => {
                    capture()
                })
            }

            const edit = () => router.push({ name: 'events.show', params: { id: <any>eventModel.value.id } })

            const del = (eventModel: Event) => {
                state.overlay = true
                store.dispatch('event/delete', { id: eventModel.id }).then(() => {
                    router.push({ name: 'events' })
                })
            }

            const items = () => {
                let items = <any>[
                    { text: i18n.t('general_label_created_by'), value: enums.creatorTitle(eventModel.value.creator) },
                ]
                if (eventModel.value.userId) {
                    items = items.concat([{
                        text: i18n.t('general_label_user'),
                        value: eventModel.value.user.fullName,
                        route: { name: 'trainees.show', params: <any>{ id: eventModel.value.userId }, query: { tab: '6' } }
                    }])
                }
                items = items.concat([
                    { text: i18n.t('event_label_event_type'), value: enums.eventTypeTitle(eventModel.value.eventType) },
                    { text: i18n.t('event_label_start_at'), value: eventModel.value.startAt?.format('DD/MM/YYYY HH:mm') || '' },
                    { text: i18n.t('event_label_end_at'), value: eventModel.value.endAt?.format('DD/MM/YYYY HH:mm') || '' },
                    { text: i18n.t('event_label_repeat_type'), value: enums.repeatTypeTitle(eventModel.value.repeatType) },
                    { text: i18n.t('event_label_repeat_count'), value: eventModel.value.repeatCount },
                    { text: i18n.t('event_label_status'), value: enums.eventStatusTitle(eventModel.value.status || '') },
                    { text: i18n.t('event_label_location'), value: eventModel.value.location || '-' },
                    { text: i18n.t('general_label_coordinates'), value: eventModel.value.coordinatesTitle },
                    { text: i18n.t('event_label_private'), value: eventModel.value.private ? i18n.t('general_text_yes') : i18n.t('general_text_no') },
                    { text: i18n.t('event_label_goal'), value: enums.targetTypeTitle(eventModel.value.targetType) },
                    { text: i18n.t('event_label_reward'), value: eventModel.value.reward || '-' },
                    { text: i18n.t('event_label_registration_url'), value: eventModel.value.registrationUrl || '-' },
                ])
                if (eventModel.value.distance) {
                    items = items.concat([{ text: i18n.t('event_label_distance'), value: eventModel.value.distance + ' m' }])
                }
                if (eventModel.value.duration !== '00:00:00.000') {
                    items = items.concat([{ text: i18n.t('event_label_duration'), value: time.format(eventModel.value.duration) }])
                }
                if (eventModel.value.stroke.exists()) {
                    items = items.concat([{ text: i18n.t('event_label_stroke'), value: eventModel.value.stroke.name }])
                }

                return items
            }

            const participantUpdated = (updatedQueries: any) => {
                participantQueries.page = updatedQueries.page
                capture()
            }

            const _exportUsers = () => {
                state.overlay = true
                useUserHttp()._export(participantQueries).then((data: any) => {
                    state.overlay = false
                    const e = document.createElement('a')
                    e.href = window.URL.createObjectURL(data)
                    e.setAttribute('download', 'Participants.csv')
                    document.body.appendChild(e)
                    e.click()
                })
            }

            const _exportRanks = () => {
                state.overlay = true
                useRankHttp()._export({ event_id: id, schedule_id: state.scheduleId }).then((data: any) => {
                    state.overlay = false
                    const e = document.createElement('a')
                    e.href = window.URL.createObjectURL(data)
                    e.setAttribute('download', 'Ranks.csv')
                    document.body.appendChild(e)
                    e.click()
                })
            }

            watch(() => [state.scheduleId], (newValue: any) => {
               captureRanks()
            })

            onMounted(() => {
                capture()
                captureRanks()
            })

            return {
                capture,
                items,
                event: eventModel.value,
                participants: participants.value,
                ranks: ranks.value,
                schedules,
                moment,
                edit,
                del,
                suspend,
                participantQueries,
                participantUpdated,
                _exportUsers,
                _exportRanks,
                ...useI18n(),
                ...useEnums(),
                ...toRefs(state)
            }
        }
    })
</script>
