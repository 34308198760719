export interface IStatPeriod {
    day: number
    week: number
    month: number
    year: number
    total: number
}

export interface IStat {
    key: string
    value: number
}

export interface StatPayload {
    registrations: IStatPeriod
    workout_executions: IStatPeriod
    program_executions: IStatPeriod
    user_genders: IStat[]
    user_ages: IStat[]
    user_levels: IStat[]
    user_strokes: IStat[]
    user_countries: IStat[]
    user_cities: IStat[]
}

export class Stat {

    registrations: IStatPeriod = { day: 0, week: 0, month: 0, year: 0, total: 0 }
    workoutExecutions: IStatPeriod = { day: 0, week: 0, month: 0, year: 0, total: 0 }
    programExecutions: IStatPeriod = { day: 0, week: 0, month: 0, year: 0, total: 0 }
    userGenders: IStat[]
    userAges: IStat[]
    userLevels: IStat[]
    userStrokes: IStat[]
    userCountries: IStat[]
    userCities: IStat[]

    constructor(data: StatPayload) {
        this.registrations = data.registrations
        this.workoutExecutions = data.workout_executions
        this.programExecutions = data.program_executions
        this.userGenders = data.user_genders
        this.userAges = data.user_ages
        this.userLevels = data.user_levels
        this.userStrokes = data.user_strokes
        this.userCountries = data.user_countries
        this.userCities = data.user_cities
    }

    fill(data: StatPayload): this {
        this.registrations = data.registrations
        this.workoutExecutions = data.workout_executions
        this.programExecutions = data.program_executions
        this.userGenders = data.user_genders
        this.userAges = data.user_ages
        this.userLevels = data.user_levels
        this.userStrokes = data.user_strokes
        this.userCountries = data.user_countries
        this.userCities = data.user_cities
        return this
    }

    unset(): this {
        this.fill(<any>{})
        return this
    }
}
