import moment, { Moment } from 'moment/moment'
import { filter, IPayload } from '@/core/utils/payload-helper'
import { Stroke, StrokePayload } from '@/core/models/stroke'
import { Role, RolePayload } from '@/core/models/role'
import { Goal, GoalPayload } from '@/core/models/goal'
import { TrainingProgram, TrainingProgramPayload } from '@/core/models/training-program'
import { WorkoutExecution, WorkoutExecutionPayload } from '@/core/models/workout-execution'

export interface ILocationCoordinates {
    latitude: number
    longitude: number
}

export interface UserPayload {
    id: number
    language_id: string
    first_name: string
    last_name: string
    email: string
    email_verified_at?: string
    phone: string
    password?: string
    password_confirmation?: string
    gender: string
    birth_date: string
    photo_url: string
    city: string
    country_code_iso: string
    location_coordinates: ILocationCoordinates
    os: string
    weight: number
    weight_unit: string
    height: number
    height_unit: string
    training_count_week: number
    swimming_goal_meters: number
    swimming_locations?: string[]
    level: string
    distance_unit: string
    color_mode: string
    registered_at: string
    apple: any[]
    garmin: any[]
    strava: any[]
    allow_face_id: boolean
    allow_notifications: boolean
    accept_terms: boolean
    accept_confidentiality: boolean
    status: string
    preferred_locale: string
    provider: string
    permissions?: string[]
    roles?: RolePayload[]
    user_goals?: GoalPayload[]
    user_strokes?: StrokePayload[]
    workout_executions?: WorkoutExecutionPayload[]
    user_training_program?: TrainingProgramPayload
    workout_executions_count?: number
}

export class User {

    id: number
    languageId: string
    firstName: string
    lastName: string
    email: string
    emailVerifiedAt?: Moment
    phone: string
    password?: string
    passwordConfirmation?: string
    gender: string
    birthDate: string
    photoUrl: string
    city: string
    countryCodeIso: string
    locationCoordinates: ILocationCoordinates
    os: string
    weight: number | null
    weightUnit: string
    height: number | null
    heightUnit: string
    trainingCountWeek: number
    swimmingGoalMeters: number
    swimmingLocations: string[]
    level: string
    distanceUnit: string
    colorMode: string
    registeredAt: Moment
    apple: any[]
    garmin: any[]
    strava: any[]
    allowFaceId: boolean
    allowNotifications: boolean
    acceptTerms: boolean
    acceptConfidentiality: boolean
    status: string
    preferredLocale: string
    provider: string
    permissions: string[]
    roles: Role[] = <Role[]>[]
    roleIds: number[] = []
    goals: Goal[] = <Goal[]>[]
    strokes: Stroke[] = <Stroke[]>[]
    workoutExecutions: WorkoutExecution[] = <WorkoutExecution[]>[]
    workoutExecutionsCount: number
    program?: TrainingProgram

    constructor(data: UserPayload) {
        this.id = data.id
        this.languageId = data.language_id
        this.firstName = data.first_name
        this.lastName = data.last_name || ''
        this.email = data.email
        this.emailVerifiedAt = data.email_verified_at ? moment(data.email_verified_at) : undefined
        this.phone = data.phone
        this.password = data.password
        this.gender = data.gender
        this.birthDate = data.birth_date
        this.photoUrl = data.photo_url
        this.city = data.city
        this.countryCodeIso = data.country_code_iso
        this.locationCoordinates = data.location_coordinates
        this.os = data.os
        this.weight = data.weight ? data.weight : null
        this.weightUnit = data.weight_unit
        this.height = data.height ? data.height : null
        this.heightUnit = data.height_unit
        this.trainingCountWeek = data.training_count_week
        this.swimmingGoalMeters = data.swimming_goal_meters
        this.swimmingLocations = data.swimming_locations || []
        this.level = data.level
        this.distanceUnit = data.distance_unit
        this.colorMode = data.color_mode
        this.registeredAt = moment(data.registered_at)
        this.apple = data.apple
        this.garmin = data.garmin
        this.strava = data.strava
        this.allowFaceId = data.allow_face_id
        this.allowNotifications = data.allow_notifications
        this.acceptTerms = data.accept_terms
        this.acceptConfidentiality = data.accept_confidentiality
        this.status = data.status
        this.preferredLocale = data.preferred_locale
        this.provider = data.provider
        this.permissions = data.permissions || []
        this.workoutExecutionsCount = data.workout_executions_count || 0
        this.capture(data)
    }

    fill(data: UserPayload): this {
        this.id = data.id
        this.languageId = data.language_id
        this.firstName = data.first_name
        this.lastName = data.last_name || ''
        this.email = data.email
        this.emailVerifiedAt = data.email_verified_at ? moment(data.email_verified_at) : undefined
        this.phone = data.phone
        this.password = data.password
        this.gender = data.gender
        this.birthDate = data.birth_date
        this.photoUrl = data.photo_url
        this.city = data.city
        this.countryCodeIso = data.country_code_iso
        this.locationCoordinates = data.location_coordinates
        this.os = data.os
        this.weight = data.weight ? data.weight : null
        this.weightUnit = data.weight_unit
        this.height = data.height ? data.height : null
        this.heightUnit = data.height_unit
        this.trainingCountWeek = data.training_count_week
        this.swimmingGoalMeters = data.swimming_goal_meters
        this.swimmingLocations = data.swimming_locations || []
        this.level = data.level
        this.distanceUnit = data.distance_unit
        this.colorMode = data.color_mode
        this.registeredAt = moment(data.registered_at)
        this.apple = data.apple
        this.garmin = data.garmin
        this.strava = data.strava
        this.allowFaceId = data.allow_face_id
        this.allowNotifications = data.allow_notifications
        this.acceptTerms = data.accept_terms
        this.acceptConfidentiality = data.accept_confidentiality
        this.status = data.status
        this.preferredLocale = data.preferred_locale
        this.provider = data.provider
        this.permissions = data.permissions || []
        this.workoutExecutionsCount = data.workout_executions_count || 0
        this.capture(data)
        return this
    }

    capture(data: UserPayload): this {
        this.roles = <Role[]>[]
        this.roleIds = <number[]>[]
        data.roles?.map((item) => {
            this.roles.push(new Role(item))
            this.roleIds.push(item.id)
        })

        this.goals = <Goal[]>[]
        data.user_goals?.map(item => this.goals.push(new Goal(item)))

        this.strokes = <Stroke[]>[]
        data.user_strokes?.filter(item => item.enabled).map(item => this.strokes.push(new Stroke(item)))

        this.workoutExecutions = <WorkoutExecution[]>[]
        data.workout_executions?.map(item => this.workoutExecutions.push(new WorkoutExecution(item)))

        if (data.user_training_program) {
            this.program = new TrainingProgram(data.user_training_program)
        }
        return this
    }

    public get fullName(): string {
        return this.firstName + (this.lastName ? ' ' + this.lastName : '')
    }

    public get photo(): string {
        return this.photoUrl || '/images/no-avatar.svg'
    }

    public get favoriteStroke(): Stroke | undefined {
        return this.strokes.filter(x => typeof x !== undefined).shift()
    }

    public get favoriteStrokeTitle(): string {
        return this.favoriteStroke ? this.favoriteStroke.name : '-'
    }

    public get goalsStrTitle(): string {
        let args = <any>[]
        this.goals.filter(item => item.enabled).map(item => args.push(item.name))
        return args.join(' / ')
    }

    public get rolesStrTitle(): string {
        let args = <any>[]
        this.roles.map(item => args.push(item.name))
        return args.join(' / ')
    }

    public get coordinatesTitle(): string {
        if (this.locationCoordinates) {
            return this.locationCoordinates.latitude + ' / ' +  this.locationCoordinates.longitude
        }
        return '-'
    }

    public get suspended(): boolean {
        return this.status === 'suspended'
    }

    public get deleted(): boolean {
        return this.status === 'deleted'
    }

    public get watches(): any[] {
        let watches = <any>[]
        if (this.apple) {
            watches.push('apple')
        }
        if (this.garmin) {
            watches.push('garmin')
        }
        return watches
    }

    public get apps(): any[] {
        let apps = <any>[]
        if (this.strava) {
            apps.push('strava')
        }
        return apps
    }

    permitted(permission: string): boolean {
        return this.permissions.filter(item => item === '*' || item === permission).length > 0
    }

    notPermitted(permission: string): boolean {
        return ! this.permitted(permission)
    }

    exists(): boolean {
        return !! this.id
    }

    unset(): void {
        this.fill(<any>{})
        this.program = undefined
        this.roleIds = <number[]>[]
    }

    isNotDeleted(): boolean {
        return this.status !== 'deleted'
    }

    rolesPayload(): RolePayload[] {
        const roles = <any>[]
        this.roleIds.map(id => roles.push(<any>{ id: id }))
        return roles
    }

    goalsPayload(): GoalPayload[] {
        const goals = <GoalPayload[]>[]
        this.goals.filter(item => item.enabled).map(item => goals.push(item.payload()))
        return goals
    }

    strokesPayload(): StrokePayload[] {
        const strokes = <StrokePayload[]>[]
        this.strokes.filter(item => item.enabled).map(item => strokes.push(item.payload()))
        return strokes
    }

    payload(fillable: string[] = []): UserPayload | IPayload {
        let payload = {
            id: this.id,
            language_id: this.languageId,
            first_name: this.firstName,
            last_name: this.lastName,
            email: this.email,
            email_verified_at: this.emailVerifiedAt ? this.emailVerifiedAt.format('YYYY-MM-DD HH:mm:ss') : undefined,
            phone: this.phone,
            password: this.password,
            password_confirmation: this.passwordConfirmation,
            gender: this.gender,
            birth_date: this.birthDate,
            photo_url: this.photoUrl,
            city: this.city,
            country_code_iso: this.countryCodeIso,
            location_coordinates: this.locationCoordinates || {},
            weight: Number(this.weight),
            weight_unit: this.weightUnit,
            height: Number(this.height),
            height_unit: this.heightUnit,
            training_count_week: this.trainingCountWeek,
            swimming_goal_meters: this.swimmingGoalMeters,
            swimming_locations: this.swimmingLocations,
            level: this.level,
            distance_unit: this.distanceUnit,
            color_mode: this.colorMode,
            registered_at: this.registeredAt.format('YYYY-MM-DD HH:mm:ss'),
            allow_face_id: this.allowFaceId,
            allow_notifications: this.allowNotifications,
            accept_terms: this.acceptTerms,
            accept_confidentiality: this.acceptConfidentiality,
            status: this.status,
            preferred_locale: this.preferredLocale,
            roles: this.rolesPayload(),
            user_goals: this.goalsPayload(),
            user_strokes: this.strokesPayload(),
        }
        return filter(payload, fillable)
    }
}
