import Vue from 'vue'
import Vuex from 'vuex'
import * as _ from 'lodash'
import { Notification, RootState } from './store-types'
import { user } from './modules/user'
import { userStat } from './modules/user-stat'
import { translation } from './modules/translation'
import { page } from './modules/page'
import { param } from './modules/param'
import { workout } from './modules/workout'
import { workoutExecution } from './modules/workout-execution'
import { programExecution } from './modules/program-execution'
import { healthSection } from './modules/health-section'
import { healthSectionCollection } from './modules/health-section-collection'
import { healthArticle } from './modules/health-article'
import { healthArticleCollection } from './modules/health-article-collection'
import { glossarySection } from './modules/glossary-section'
import { glossarySectionCollection } from './modules/glossary-section-collection'
import { glossaryDefinition } from './modules/glossary-definition'
import { glossaryDefinitionCollection } from './modules/glossary-definition-collection'
import { badgeSection } from './modules/badge-section'
import { badgeSectionCollection } from './modules/badge-section-collection'
import { badge } from './modules/badge'
import { badgeCollection } from './modules/badge-collection'
import { userComment } from './modules/user-comment'
import { userCommentCollection } from './modules/user-comment-collection'
import { program } from './modules/program'
import { event } from './modules/event'
import { group } from './modules/group'
import { role } from './modules/role'
import { feedback } from './modules/feedback'
import { feedbackStat } from './modules/feedback-stat'
import { notification } from './modules/notification'
import { complaint } from './modules/complaint'
import { userCollection } from './modules/user-collection'
import { pageCollection } from './modules/page-collection'
import { paramCollection } from './modules/param-collection'
import { goalCollection } from './modules/goal-collection'
import { strokeCollection } from './modules/stroke-collection'
import { workoutCollection } from './modules/workout-collection'
import { workoutExecutionCollection } from './modules/workout-execution-collection'
import { programCollection } from './modules/program-collection'
import { programExecutionCollection } from './modules/program-execution-collection'
import { raceExecutionCollection } from './modules/race-execution-collection'
import { eventCollection } from './modules/event-collection'
import { groupCollection } from './modules/group-collection'
import { roleCollection } from './modules/role-collection'
import { rankCollection } from './modules/rank-collection'
import { feedbackCollection } from './modules/feedback-collection'
import { notificationCollection } from './modules/notification-collection'
import { complaintCollection } from './modules/complaint-collection'
import { userStatisticCollection } from './modules/user-statistic-collection'
import { lazyTranslation } from './modules/lazy-translation'
import { stat } from './modules/stat'
import { statTotal } from './modules/stat-total'
import { filer } from './modules/filer'
import { defaults } from './defaults'

Vue.use(Vuex)

const state: RootState = {
    notifications: []
}

const store = new Vuex.Store({
    state,
    mutations: {
        notify(state: any, payload: Notification) {
            state.notifications.push({
                id: state.notifications.length + 1,
                color: payload.color || 'success',
                message: payload.message
            })
        },
        readNotification(state: any, payload: number) {
            const index = _.findIndex(state.notifications, { id: payload })
            if (index !== -1) {
                state.notifications.splice(index, 1)
            }
        },
    },
    actions: {
        notify(context: any, payload: Notification) {
            context.commit('notify', payload)
        },
        readNotification(context: any, payload: number) {
            context.commit('readNotification', payload)
        },
    },
    getters: {
        notifications: (state: any) => {
            return state.notifications
        }
    },
    modules: {
        user,
        userStat,
        translation,
        page,
        param,
        workout,
        workoutExecution,
        programExecution,
        healthSection,
        healthArticle,
        glossarySection,
        glossaryDefinition,
        badgeSection,
        badge,
        userComment,
        program,
        event,
        group,
        role,
        feedback,
        feedbackStat,
        notification,
        complaint,
        userCollection,
        pageCollection,
        paramCollection,
        goalCollection,
        strokeCollection,
        workoutCollection,
        healthSectionCollection,
        healthArticleCollection,
        glossarySectionCollection,
        glossaryDefinitionCollection,
        badgeSectionCollection,
        badgeCollection,
        workoutExecutionCollection,
        userCommentCollection,
        programCollection,
        programExecutionCollection,
        raceExecutionCollection,
        eventCollection,
        groupCollection,
        roleCollection,
        rankCollection,
        feedbackCollection,
        notificationCollection,
        complaintCollection,
        userStatisticCollection,
        lazyTranslation,
        stat,
        statTotal,
        filer,
        defaults,
    }
})

export default store

export function useStore() {
    return store
}