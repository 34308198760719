import moment, { Moment } from 'moment/moment'
import { User, UserPayload } from '@/core/models/user'
import { Stroke, StrokePayload } from '@/core/models/stroke'
import { EventSchedule, EventSchedulePayload } from '@/core/models/event-schedule'

export interface ILocationCoordinates {
    latitude: number
    longitude: number
}

export interface EventPayload {
    id: number
    user_id: number
    stroke_id: number
    creator: string
    name: string
    foreword: string
    description: string
    reward: string
    photo_url: string
    photos: string[]
    associates: string[]
    event_type: string
    target_type: string
    repeat_type: string
    repeat_count: number
    start_at: string
    end_at: string
    status?: string
    distance: number
    distance_unit: string
    duration: string
    location: string | null
    location_coordinates: ILocationCoordinates | null
    registration_url: string | null
    participant_count: number
    hide_users: boolean
    private: boolean
    enabled: boolean
    created_at?: string
    user?: UserPayload
    stroke?: StrokePayload
    schedule?: EventSchedulePayload
    schedules: EventSchedulePayload[]
    participants: UserPayload[]
    translations?: {}
}

export class Event {

    id: number
    userId: number
    strokeId: number
    creator: string
    name: string
    foreword: string
    description: string
    reward: string
    photo?: File
    photoUrl: string
    photoUps?: File[]
    photos: string[]
    associates: string[]
    eventType: string
    targetType: string
    repeatType: string
    repeatCount: number
    startAt: Moment
    endAt: Moment
    status?: string
    distance: number
    distanceUnit: string
    duration: string
    location: string | null
    locationCoordinates: ILocationCoordinates | null
    registrationUrl: string | null
    participantCount: number
    hideUsers: boolean
    private: boolean
    enabled: boolean
    createdAt?: Moment
    user: User
    stroke: Stroke
    schedule: EventSchedule
    schedules: EventSchedule[] = <EventSchedule[]>[]
    participants: User[] = <User[]>[]
    translations?: {} = <any>{}

    constructor(data: EventPayload) {
        this.id = data.id
        this.userId = data.user_id
        this.strokeId = data.stroke_id
        this.creator = data.creator
        this.name = data.name
        this.foreword = data.foreword
        this.description = data.description
        this.reward = data.reward
        this.photoUrl = data.photo_url
        this.photos = data.photos
        this.associates = data.associates
        this.eventType = data.event_type
        this.targetType = data.target_type
        this.repeatType = data.repeat_type
        this.repeatCount = data.repeat_count || 1
        this.startAt = moment(data.start_at)
        this.endAt = moment(data.end_at)
        this.status = data.status
        this.distance = data.distance || 0
        this.distanceUnit = data.distance_unit
        this.duration = data.duration || '00:00:00.000'
        this.location = data.location
        this.locationCoordinates = data.location_coordinates
        this.registrationUrl = data.registration_url
        this.participantCount = data.participant_count
        this.hideUsers = data.hide_users
        this.private = data.private
        this.enabled = data.enabled
        this.createdAt = data.created_at ? moment(data.created_at) : undefined
        this.user = new User(data.user || <UserPayload>{})
        this.stroke = new Stroke(data.stroke || <StrokePayload>{})
        this.schedule = new EventSchedule(data.schedule || <EventSchedulePayload>{})
        this.capture(data)
    }

    fill(data: EventPayload): this {
        this.id = data.id
        this.userId = data.user_id
        this.strokeId = data.stroke_id
        this.creator = data.creator
        this.name = data.name
        this.foreword = data.foreword
        this.description = data.description
        this.reward = data.reward
        this.photoUrl = data.photo_url
        this.photos = data.photos
        this.associates = data.associates
        this.eventType = data.event_type
        this.targetType = data.target_type
        this.repeatType = data.repeat_type
        this.repeatCount = data.repeat_count || 1
        this.startAt = moment(data.start_at)
        this.endAt = moment(data.end_at)
        this.status = data.status
        this.distance = data.distance || 0
        this.distanceUnit = data.distance_unit
        this.duration = data.duration || '00:00:00.000'
        this.location = data.location
        this.locationCoordinates = data.location_coordinates
        this.registrationUrl = data.registration_url
        this.participantCount = data.participant_count
        this.hideUsers = data.hide_users
        this.private = data.private
        this.enabled = data.enabled
        this.createdAt = data.created_at ? moment(data.created_at) : undefined
        this.user = new User(data.user || <UserPayload>{})
        this.stroke = new Stroke(data.stroke || <StrokePayload>{})
        this.schedule = new EventSchedule(data.schedule || <EventSchedulePayload>{})
        this.capture(data)
        return this
    }

    capture(data: EventPayload): this {
        if (data.schedules) {
            this.schedules = <EventSchedule[]>[]
            data.schedules.map(item => this.schedules.push(new EventSchedule(item)))
        }
        if (data.participants) {
            this.participants = <User[]>[]
            data.participants.map(item => this.participants.push(new User(item)))
        }
        if (data.translations) {
            this.translations = data.translations
        }
        return this
    }

    public get coordinatesTitle(): string {
        if (this.locationCoordinates) {
            return this.locationCoordinates.latitude + ' / ' +  this.locationCoordinates.longitude
        }
        return '-'
    }

    delPhoto(number: number): this {
        const photos = this.photos.map(i => JSON.parse(JSON.stringify(i)))
        photos.splice(number, 1)
        this.photos = []
        photos.map(i => this.photos.push(i))
        return this
    }

    updatePhotoNumber(currentNumber: number, number: number): this {
        const set = JSON.parse(JSON.stringify(this.photos[currentNumber]))
        this.photos.splice(currentNumber, 1)
        const photos = this.photos.map(i => JSON.parse(JSON.stringify(i)))
        photos.splice(number, 0, set)
        this.photos = []
        photos.map(i => this.photos.push(i))
        return this
    }

    exists(): boolean {
        return !! this.id
    }

    unset(): this {
        this.fill(<any>{})
        this.photo = undefined
        this.photoUps = undefined
        this.associates = <any>[]
        this.schedules = <any>[]
        this.participants = <any>[]
        this.translations = {}
        return this
    }

    __schedules(): EventSchedulePayload[] {
        const schedules = <EventSchedulePayload[]>[]
        this.schedules.map(item => schedules.push(item.payload()))
        return schedules
    }

    __participants(): UserPayload[] {
        const participants = <UserPayload[]>[]
        this.participants.map(item => participants.push(<UserPayload>item.payload()))
        return participants
    }

    payload(): EventPayload | any {
        if (this.repeatType === 'one_time') {
            this.repeatCount = 1
        }
        return {
            id: this.id,
            user_id: this.userId,
            stroke_id: this.strokeId,
            creator: this.creator,
            name: this.name,
            foreword: this.foreword,
            description: this.description,
            reward: this.reward,
            photos: this.photos,
            event_type: this.eventType,
            target_type: this.targetType,
            repeat_type: this.repeatType,
            distance: this.distance,
            distance_unit: this.distanceUnit,
            duration: this.duration,
            location: this.location,
            location_coordinates: this.locationCoordinates || null,
            registration_url: this.registrationUrl || null,
            hide_users: this.hideUsers,
            private: this.private,
            enabled: this.enabled,
            start_at: this.startAt.toISOString(),
            end_at: this.endAt.toISOString(),
            repeat_count: this.repeatCount,
            associates: this.associates || [],
            participants: this.__participants(),
            translations: this.translations,
        }
    }
}
